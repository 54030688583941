<template>
    <li :class="className + ' post-analytics-element ig-analytics-element flex-col mb16'">
        <div class="flex-container flex-center-align flex-space-between-justyfy no-grow mt8">
            <p class="bebas nomargin">
                <span>{{post.mediaProductType.toLowerCase() === 'story' ? 'story' : 'post'}}</span>
                <span v-if="post.advertisements && post.advertisements.length > 0 "> con inserzioni</span>
            </p>
        </div>
        <a 
            @click="()=>$emit('list-select', post)"
            class="layout-list-item-btn flex-container"
        >
            <RetryingImageSocial
                :url="post.mediaUrl"
                :id="post.postId"
                :fallbackImage="true"
            />
            <div class="flex-container flex-col w80">
                <p class="layout-list-item-subtitle f12 nomargin pl8 lh24"><i>{{post.postId || 'no id from db :S'}}</i></p>
                <div class="layout-list-item-title nomargin pl8 lh24 pr8">
                        {{post.caption}} 
                </div>
            </div>
            
            <InflooButton
                 @click="() =>openLocation(post.permalink)"
                 :className="'add-post-to-campaign button primary flex-container flex-center-align flex-center-justify self-stretch '"
            >
                    vedi
            </InflooButton> 
        </a>
        <text-input
            :label="'Costo'"
            :value="formatNumber(post.cost) + ' €'"
            :editing="false"
            :name="'clicksLink'"
            :className="'flex-container flex-col flex-start-align flex-start-justify pt16 lh24'"
            :labelClassName="'nomargin'"
        >
        </text-input>
        
        <AdvertiserFacebookInsight
            v-if="isAdvertiser()"
            :insight="post.insight"
        ></AdvertiserFacebookInsight> 
        <InstagramInsight
            v-else
            :insight="post.insight"
            :likeCount="post.likeCount"
            :advertisements="post.advertisements"
        ></InstagramInsight> 
       <div
            v-if="post.advertisements && post.advertisements.length > 0"
        >
        <div class="flex-container flex-center-align flex-space-between-justyfy no-grow">
            <p class="bebas nomargin">inserzioni</p>
        </div>
            <ul class="flex-container flex-col nomargin nopadding  pr8 mr8">
                <li 
                    v-for="adv in post.advertisements" 
                    :key="adv.id" 
                    :class="className + ' post-analytics-element flex-col'"
                >
                    <a 
                        @click="()=>$emit('list-select', post)"
                        class=" flex-container"
                    >
                        
                        <div class="flex-container flex-col w80">
                            <p class="layout-list-item-subtitle f12 nomargin lh24 pb8"><i>{{adv.id || 'no id from db :S'}}</i></p>
                            <div class="layout-list-item-title nomargin lh24">
                                    {{adv.label || ''}} 
                            </div>
                        </div>   
                    </a>
                    <div class="flex-container flex-start-align">
                        <text-input
                            :label="'Copertura a Pagamento'"
                            :value="formatNumber(adv.reach) || 0"
                            :editing="false"
                            :name="'reach'"
                            :className="'flex-container flex-col flex-start-align flex-start-justify'"
                            :labelClassName="'nomargin'"
                        >
                        </text-input>
                        <text-input
                            :label="'Impressions a pagamento'"
                            :value="formatNumber(adv.impressions) || 0"
                            :editing="false"
                            :name="'impressions'"
                            :className="'flex-container flex-col flex-start-align flex-start-justify'"
                            :labelClassName="'nomargin'"
                        >
                        </text-input>
                        <text-input
                            :label="'linkClicks'"
                            :value="formatNumber(adv.linkClicks) || 0"
                            :editing="false"
                            :name="'organicCoverage'"
                            :className="'flex-container flex-col flex-start-align flex-start-justify'"
                            :labelClassName="'nomargin'"
                        >
                        </text-input>
                        
                    </div>
                    <div class="flex-container flex-start-align">
                        <text-input
                            :label="'pageEngagement'"
                            :value="formatNumber(adv.pageEngagement) || 0"
                            :editing="false"
                            :name="'reach'"
                            :className="'flex-container flex-col flex-start-align flex-start-justify'"
                            :labelClassName="'nomargin'"
                        >
                        </text-input>
                        <text-input
                            :label="'postEngagement'"
                            :value="formatNumber(adv.postEngagement) || 0"
                            :editing="false"
                            :name="'impressions'"
                            :className="'flex-container flex-col flex-start-align flex-start-justify'"
                            :labelClassName="'nomargin'"
                        >
                        </text-input>
                        <text-input
                            :label="'reactions'"
                            :value="formatNumber(adv.reactions) || 0"
                            :editing="false"
                            :name="'organicCoverage'"
                            :className="'flex-container flex-col flex-start-align flex-start-justify'"
                            :labelClassName="'nomargin'"
                        >
                        </text-input>
                        
                    </div>
                    <div class="flex-container flex-start-align">
                        <text-input
                            :label="'postSave'"
                            :value="formatNumber(adv.postSave) || 0"
                            :editing="false"
                            :name="'reach'"
                            :className="'flex-container flex-col flex-start-align flex-start-justify'"
                            :labelClassName="'nomargin'"
                        >
                        </text-input>
                        
                        
                    </div>
                </li>
            </ul>
       </div>
    </li>
</template>
<script>
import {ref} from 'vue'
import {useCapabilities} from '@/utils/Capabilities'
import InstagramInsight from '@/components/campaigns/analytics/instagram/InstagramInsight'
import AdvertiserFacebookInsight from '@/components/campaigns/analytics/facebook/AdvertiserFacebookInsight'
import InflooButton from '@/components/buttons/InflooButton'
import TextInput from '@/components/formelements/TextInput'
import {openLocation} from '@/utils/CampaignFunctions'
import {formatNumber} from '@/utils/NumberFunctions'
import RetryingImageSocial from '@/components/RetryingImageSocial'
export default {
    props:{
        post:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item'
        },
    },
    components:{
       InstagramInsight,
       AdvertiserFacebookInsight,
       InflooButton,
       RetryingImageSocial,
       TextInput
    },
    setup(props){
       
       const {isAdvertiser} = useCapabilities()
       let id = ref(props.post.postId)
       if(!id.value){
           try {
               const arr = props.post.permalink.split('/').slice(3)
               id.value = arr[0]+'_'+arr[2]
           } catch (error) {
              id.value = 'id not found' 
           }
           
       }
        return{
            openLocation,
            id,
            isAdvertiser,
            formatNumber
        }
    }

}
</script>
<style>
.ig-analytics-element{
    border-top: solid 1px #2cbeff;
}
.ig-analytics-element.layout-list-item, .ig-analytics-element .layout-list-item{
    border-bottom: none;
}
.post-analytics-element img {
    height: 36px;
    width: 36px;
    object-fit: cover;

}
.post-analytics-element label h3 {
    font-size: .9em;
}
.add-post-to-campaign{
     max-width: 68px;
}

</style>