<template>
    <div class="flex-container flex-col w100">
        <div v-if="hasPosts" class="post-container flex-container flex-col mt8">
            <div class="flex-container flex-center-align flex-space-between-justyfy no-grow">
                <p class="bebas nomargin">post</p>
            </div>
            <slot name="posts"></slot>
        </div>
        <div v-if="hasAdverts" class="adverts-container flex-container flex-col">
            <div class="flex-container flex-center-align flex-space-between-justyfy no-grow">
                <p class="bebas nomargin">GHOST</p>
            </div>
            <slot name="adverts"></slot>
        </div>    
    </div>
</template>
<script>
export default {
    props:{
        hasPosts:{
            type:Boolean,
            default:true
        },
        hasAdverts:{
            type:Boolean,
            default:true
        }
    } 
}
</script>
