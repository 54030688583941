/**
 * Calcola i totali di un insieme di adverts (tutti i "ghost/dark")
 * @param {Array} ads 
 * @returns 
 */

 export const getFacebookAdvertInsightTotals = (ads) =>{
    const total = {
        cost:0,
        linkClicks:0,
        otherClicks:0,
        photoView:0,
        reactions:0,
        comments:0,
        shared:0,
        impressions:0,
        impressionsSponsored:0,
        impressionsOrganic:0,
        totalCoverage:0,
        sponsoredCoverage:0,
        organicCoverage:0,
        videoViews3S:0,
        videoViews3SUnique:0,
        videoViews3SOrganic:0,
        videoViews3SSponsored:0,
        videoViews10S:0,
        videoViews10SUnique:0,
        videoViews10SOrganic:0,
        videoViews10SSponsored:0,
        videoViews15S:0,
        videoPlay:0
    }
    for(let i=0; i< ads.length; i++){
        const post = ads[i]
        const insight = post.insight
        if(insight){
            Object.keys(total).forEach(key =>{
                if(insight[key]){
                    total[key] += insight[key]
                }
            })
        }
        total.cost += post.cost
        
    }
    return total
    
}

/**
 * Calcola i totali di un insieme di post
 * @param {Array} posts 
 * @returns 
 */


export const getFacebookPostsInsightTotals = (posts) =>{
    const total = {
        cost:0,
        linkClicks:0,
        otherClicks:0,
        photoView:0,
        reactions:0,
        comments:0,
        shared:0,
        impressions:0,
        impressionsSponsored:0,
        impressionsOrganic:0,
        totalCoverage:0,
        sponsoredCoverage:0,
        organicCoverage:0,
        videoViews3S:0,
        videoViews3SUnique:0,
        videoViews3SOrganic:0,
        videoViews3SSponsored:0,
        videoViews10S:0,
        videoViews10SUnique:0,
        videoViews10SOrganic:0,
        videoViews10SSponsored:0,
        videoViews15S:0,
        videoPlay:0
    }
    for(let i=0; i< posts.length; i++){
        const post = posts[i]
        const insight = post.insight
        if(insight){
            Object.keys(total).forEach(key =>{
                if(insight[key]){
                    total[key] += insight[key]
                }

            })
        }
        total.cost += post.cost
        
        
    }
    
    return total
    
}

export const getInstagramPostsInsightTotals = (posts) =>{
    const total = {
        cost:0,
        albumEngagement: 0,
        albumImpressions: 0,
        albumReach: 0,
        albumSaved: 0,
        albumVideoViews: 0, 
        engagement: 0,
        exits: 0,
        impressions: 0,
        organicCoverage: 0,
        reach: 0,
        replies: 0,
        saved: 0,
        sponsoredCoverage: 0,
        tapsBack: 0,
        tapsForward: 0,
        totalCoverage: 0,
        videoViews: 0,
        likeCount:0,
        advertisements: []
    }
    for(let i=0; i< posts.length; i++){
        const post = posts[i]
        const insight = post.insight
        if(insight){
            Object.keys(total).forEach(key =>{
                if(key !== 'campaignId' && key !== 'postId' && key !== 'socialId' && key !== 'advertisements') {
                    if(insight[key]){
                        total[key] += insight[key]
                    }
                }
                if(key === 'advertisements'){ 
                  total.advertisements.push(...post.advertisements)  
                }
                

            })
        }
        total.cost += post.cost
        total.likeCount += post.likeCount
        
        
    }
    
    return total
    
}