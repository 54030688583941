<template>
    <label :class="className">
        <div class="flex-container heading-and-error">
            <h3 class="bebas nomargin">{{label}}</h3>
            <ErrorMessage v-if="editing && isRequired" :validationStatus="vuel.text" />
        </div>
        <div class="flex-container flex-center-align">
            <span class="pr8">
                €
            </span>
            <input
                v-if="editing && isRequired" 
                required 
                v-model="text" 
                :name="name"
                type="number" 
                min="0" 
                step="any"
                :placeholder="placeholder"
                class="noborder nopadding pl8 w100 w100"
                @blur="handleBlur('text')"
                @input="()=>$emit('input', {text:text , name:name})"
            />
            <input
                v-if="editing && !isRequired" 
                v-model="text" 
                :name="name"
                type="number" 
                min="0" 
                step="any"
                :placeholder="placeholder"
                class="noborder nopadding pl8 w100 w100"
                @blur="handleBlur('text')"
                @input="()=>$emit('input', {text:text , name:name})"
            />
            <input 
                v-else-if="!editing" 
                type="number" 
                :placeholder="placeholder"  
                class="noborder nopadding pl8 w100" 
                disabled 
                :value="value"
            >
            
        </div>
        
    </label>
   
</template>
<script>
import { ref} from 'vue'
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators"
import ErrorMessage from "./ErrorMessage";

export default {
    emits:['input'],
    props:{
        name:{
            type: String,
            default:''
        },
        label:{
            type: String,
            default:''
        },
        placeholder:{
            type:String,
            default:'insert value here'
        },
        value:{
            type:[String, Number],
            default:0
        },
        editing:{
            type:Boolean,
            default:false
        },
        className:{
            type:String,
            default:'flex-container flex-col pr24'
        },
        isRequired:{
            type:Boolean,
            default:true
        }
    },
    components:{
        ErrorMessage
    },
    setup(props){
        
        const text = ref(props.value);
        
        
        let vuel 
        if(props.isRequired){
            const rules = { text: { required }}
            vuel = useVuelidate(rules,{ text })
        }
        
        const handleBlur = (key) =>{
           if(vuel) vuel.value[key].$dirty = true;
        }
        return{
            text,
            vuel,
            handleBlur
        }
    }
}
</script>