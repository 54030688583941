<template>
     <div
        v-if="insight" 
        class="flex-container flex-col lh24 ">
            <div class="flex-container flex-col ">
                <div class="flex-container flex-start-align">
                    <text-input
                        :label="'Copertura'"
                        :value="formatNumber(insight.totalCoverage) || 0"
                        :editing="false"
                        :name="'totalCoverage'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Copertura organica'"
                        :value="formatNumber(insight.organicCoverage) || 0"
                        :editing="false"
                        :name="'organicCoverage'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Copertura a pagamento'"
                        :value="formatNumber(insight.sponsoredCoverage) || 0"
                        :editing="false"
                        :name="'sponsoredCoverage'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    
                </div>
                <div class="flex-container no-grow">
                    <text-input
                        :label="'Impressions'"
                        :value="formatNumber(insight.impressions + totalSponsoredImpressions )"
                        :editing="false"
                        :name="'impressions'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Impressions organiche'"
                        :value="insight.impressions ? formatNumber(insight.impressions) : 0"
                        :editing="false"
                        :name="'impressions'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Impressions a pagamento'"
                        :value="formatNumber(totalSponsoredImpressions )"
                        :editing="false"
                        :name="'impressions'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
                <div class="flex-container no-grow">
                    <text-input
                        :label="'Engagement'"
                        :value="formatNumber(insight.engagement) || 0"
                        :editing="false"
                        :name="'engagement'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify maxW33'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'like Count'"
                        :value="formatNumber(likeCount) || 0"
                        :editing="false"
                        :name="'engagement'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify maxW33'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    
                </div>
                <div class="flex-container no-grow">
                    <text-input
                        :label="'Replies'"
                        :value="formatNumber(insight.replies) || 0"
                        :editing="false"
                        :name="'replies'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Saved'"
                        :value="formatNumber(insight.saved) || 0"
                        :editing="false"
                        :name="'saved'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Exits'"
                        :value="formatNumber(insight.exits) || 0"
                        :editing="false"
                        :name="'exits'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
                
                
               
                
            </div>
        </div> 
</template>
<script>
import {inject, computed} from 'vue'
import TextInput from '@/components/formelements/TextInput'
import {formatNumber} from '@/utils/NumberFunctions'
export default {
    props:{
        insight:{
            type:Object,
            default:()=>{}
        },
        likeCount:{
            type:Number,
            default:0
        },
        advertisements:{
            type:Array,
            default:()=>[]
        }
    },
    components:{
        TextInput
    },
    setup(props){
        const campaign = inject('campaign')
        
        const totalSponsoredImpressions = computed(()=>{
            let total =0
            if(props.advertisements.length > 0){
                for (let i =0; i < props.advertisements.length; i++ ){
                    total += props.advertisements[i].impressions
                }
                return total
            }
            
            return 0
        })
        return{
            campaign,
            formatNumber,
            totalSponsoredImpressions
            
        }
    }
}
</script>